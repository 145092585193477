import { ActionInputProps } from '@pretto/website/src/components/ContentPage/components/BannerCard/components/ActionInput'
import { FormatedImage } from '@pretto/website/src/components/FormatedImage/FormatedImage'
import { slugify } from '@pretto/website/src/templates/blocs/lib/slugify'

import * as S from './RowInput.styles'

export type RowInputProps = {
  image: string
  inputProps: ActionInputProps
  isColored?: boolean
  isFirstRowWithPromises?: boolean
  isReversed?: boolean
  suptitle?: string
  text: string
  title: string
}

export const RowInput: React.FC<RowInputProps> = ({
  image,
  inputProps,
  isColored = false,
  isFirstRowWithPromises = false,
  isReversed = false,
  suptitle,
  text,
  title,
  ...props
}) => (
  <S.NewBlocRow
    isColored={isColored}
    isFirstRowWithPromises={isFirstRowWithPromises}
    isReversed={isReversed}
    {...props}
  >
    <S.ContentWrapper>
      {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

      <S.Title id={slugify(title)}>{title}</S.Title>

      <S.Text allowedTypes={['a', 'strong']}>{text}</S.Text>

      <S.Input {...inputProps} />
    </S.ContentWrapper>

    <S.ImageWrapper>
      <FormatedImage src={image} format="rectangle" isRounded />
    </S.ImageWrapper>
  </S.NewBlocRow>
)
