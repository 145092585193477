import { RowInput } from '@pretto/website/src/templates/blocs/rows/rowInput/components/RowInput'

type RowInputContainerProps = {
  isFirstRowWithPromises?: boolean
  bannerId: string
  checkMessage: string
  image: string
  inputPlaceholder: string
  isCheckMandatory?: boolean
  isColored?: boolean
  isReversed?: boolean
  successMessage: string
  suptitle?: string
  text: string
  title: string
  zap: string
}

export const RowInputContainer: React.FC<RowInputContainerProps> = ({
  isFirstRowWithPromises = false,
  bannerId,
  checkMessage,
  image,
  inputPlaceholder,
  isCheckMandatory = false,
  isColored = false,
  isReversed = false,
  successMessage,
  suptitle,
  text,
  title,
  zap,
}) => {
  const props = {
    arePromisesDisplayed: true,
    text,
    image,
    inputProps: {
      bannerId: `rowInput-${bannerId}`,
      checkMessage,
      inputPlaceholder,
      isCheckMandatory,
      successMessage,
      zap,
    },
    isColored,
    isFirstRowWithPromises,
    isReversed,
    suptitle,
    title,
  }

  return <RowInput {...props} />
}
