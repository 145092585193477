import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import { ActionInput } from '@pretto/website/src/components/ContentPage/components/BannerCard/components/ActionInput'
import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'
import { anchorStyle } from '@pretto/website/src/templates/blocs/styles/anchorStyle'

import styled from 'styled-components'

export const ROW_INPUT_BG_COLORS = {
  light: 'white',
  colored: 'neutral4',
}

export const NewBlocRow = styled(NewBlocRowComponent)`
  ${grid()};
  ${({ theme }) => theme.typos.body4};
  background-color: ${({ isColored, theme }) =>
    theme.colors[isColored ? ROW_INPUT_BG_COLORS.colored : ROW_INPUT_BG_COLORS.light]};
`

export const ContentWrapper = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => (theme.isReversed ? column([2, 6]) : column([8, 6]))};
    grid-row: 1;
    margin-bottom: auto;
    margin-top: auto;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${({ theme: { isReversed } }) => (isReversed ? column([3, 5]) : column([8, 5]))};
  }
`

export const Suptitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(2)};
`

export const Title = styled.h2`
  ${anchorStyle};
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(4)};
  hyphens: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
    hyphens: unset;
  }
`

export const Text = styled(RichContent)`
  ${({ theme }) => theme.typos.body4};
`

export const Input = styled(ActionInput)`
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(4)};
  }
`

export const ImageWrapper = styled.div`
  ${column([1, 6])};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([4, 4])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme: { isReversed } }) => (isReversed ? column([9, 5]) : column([2, 5]))};
    grid-row: 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${({ theme: { isReversed } }) => (isReversed ? column([9, 4]) : column([3, 4]))};
  }
`
